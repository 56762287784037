// custom typefaces
import 'typeface-montserrat';
import 'typeface-merriweather';

import { initDatadogRum } from './src/utils/init-datadog-rum';

require('katex/dist/katex.min.css');
require('./src/components/bootstrap-grid.min.css');
require('./src/static/facebook-pixel.js');

export const onClientEntry = () => {
  initDatadogRum({ service: 'blog-web' });
};
